.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 100px) {
  .order-sidebar-map .leaflet-container{
    width: 90vw;
    height: 50vh;
    z-index: 10;
  }

  .generator-map .leaflet-container{
    width: 32vw;
    height: 60vh;
    z-index: 10;
  }
}

@media (min-width: 640px) {
  .order-sidebar-map .leaflet-container{
    width: 92vw;
    height: 30vh;
    z-index: 10;
  }

  .generator-map .leaflet-container{
    width: 42vw;
    height: 60vh;
    z-index: 10;
  }
}

@media (min-width: 768px) {
  .order-sidebar-map .leaflet-container{
    width: 93vw;
    height: 40vh;
    z-index: 10;
  }

  .generator-map .leaflet-container{
    width: 42vw;
    height: 60vh;
    z-index: 10;
  }
}

@media (min-width: 1024px) {
  .order-sidebar-map .leaflet-container{
    width: 72vw;
    height: 30vh;
    z-index: 10;
  }

  .generator-map .leaflet-container{
    width: 42vw;
    height: 60vh;
    z-index: 10;
  }
}

@media (min-width: 1280px) {
  .order-sidebar-map .leaflet-container{
    width: 23vw;
    height: 30vh;
    z-index: 10;
  }

  .generator-map .leaflet-container{
    width: 27vw;
    height: 50vh;
    z-index: 10;
  }
}

@media (min-width: 1400px) {
  .order-sidebar-map .leaflet-container{
    width: 25vw;
    height: 30vh;
    z-index: 10;
  }

  .generator-map .leaflet-container{
    width: 27vw;
    height: 50vh;
    z-index: 10;
  }
}

@media (min-width: 1600px) {
  .order-sidebar-map .leaflet-container{
    width: 26vw;
    height: 30vh;
    z-index: 10;
  }

  .generator-map .leaflet-container{
    width: 27vw;
    height: 60vh;
    z-index: 10;
  }
}

@media (min-width: 1700px) {
  .order-sidebar-map .leaflet-container{
    width: 27vw;
    height: 30vh;
    z-index: 10;
  }

  .generator-map .leaflet-container{
    width: 27vw;
    height: 60vh;
    z-index: 10;
  }
}
